<template>
  <transition
    name="fade"
    mode="out-in"
  >
    <router-view />
  </transition>
</template>
<script>
import { registerMiniAppModuleContext, MINI_APP_MODULE_NAMESPACE } from '@/modules/miniApp'
import { defineComponent } from '@vue/composition-api'
import { mapActions } from 'vuex'
import { registerEventLog } from '@/utils/composable/eventLog/'

export default defineComponent({
  name: 'MiniAppIndex',
  setup () {
    registerMiniAppModuleContext()
    registerEventLog()
  },
  mounted () {
    this.setCurrentAskingModuleBelongsTo(MINI_APP_MODULE_NAMESPACE)
  },
  methods: {
    ...mapActions('dataSource', ['setCurrentAskingModuleBelongsTo'])
  }
})
</script>

<style lang="scss" scoped>
</style>
